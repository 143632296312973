import {makeAutoObservable} from "mobx";
import {Assets, ILastVoice, ParamsGenAudio} from "../interface";

export default class NursingStore {
    packs: any;
    cases: any;
    errors: any;
    categories: any;
    selectedCase: any;
    openTab: 'main' | 'patient' | 'dialogue' | 'investigation' | 'drug' | 'medication' | 'messages' | 'ventilation' = 'main';
    commonOpenTab: 'dialogue' | 'main' | 'investigation' | 'who' | 'patient' | 'drug' | '' = '';
    caseData: any;
    patientState: any;
    dialogue: any;
    dialogue_errors: any;
    investigation: any;
    investigation_errors: any;
    rangeDrug: any;
    range_drugs_errors: any;
    ventilation_errors: any;
    medication: any;
    paramsGenAudio: ParamsGenAudio = {
        show: false,
        info: null
    }
    currentAudio: Assets | null = null
    location: any;
    type: any;
    vacutainer_tubes: any;
    duration_unit: any;
    who: any;
    urinary_color: any;
    pupil_reaction: any;
    skin_turgor: any;
    speech: any;
    drug_type: any;
    special_states: any;
    modalCopyCase: any = null;
    messages: any = null;
    ventilation: any = null;
    lastVoice: Record<string, ILastVoice> = {};

    constructor() {
        let lastVoice: string | Record<string, ILastVoice> = localStorage.getItem('lastVoice');

        if (lastVoice) lastVoice = JSON.parse(lastVoice);

        if (typeof (lastVoice) !== 'object') lastVoice = {};

        if (lastVoice) this.lastVoice = lastVoice;

        makeAutoObservable(this);
    }

    setLastVoice(who: string, data: ILastVoice) {
        this.lastVoice[who] = data;

        localStorage.setItem('lastVoice', JSON.stringify(this.lastVoice));
    }

    setErrors(data: any) {
        this.errors = data;
    }

    setDialogueErrors(data: any) {
        this.dialogue_errors = data;
    }

    setRangeDrugsErrors(data: any) {
        this.range_drugs_errors = data;
    }

    setVentilationErrors(data: any) {
        this.ventilation_errors = data;
    }

    setInvestigationErrors(data: any) {
        this.investigation_errors = data;
    }

    setVentilation(data: any) {
        this.ventilation = data;
    }

    setMessages(data: any) {
        this.messages = data;
    }

    copyCase(data: any, flag?: boolean) {
        if (!this.cases) return;

        const idx = this.cases.findIndex((el: any) => el._id === data._id);
        if (idx === -1) {
            this.cases = [...this.cases, data];
        }

        if (flag) {
            this.selectedCase = this.cases[idx];
        }
    }

    deleteNursingCase(caseId: string) {
        if (this.cases) {
            this.cases = this.cases.filter((el: any) => el._id !== caseId);
        }

        if (this.selectedCase?._id === caseId) {
            this.selectedCase = null;

            const url = new URL(document.URL);
            url.searchParams.delete('caseId');
            window.history.replaceState({}, '', url);
        }
    }

    updateNursingCase(data: any) {
        if (!this.cases) return;

        let idx = -1;

        if (data.type === 'name') {
            if (!data.lang) return;

            idx = this.cases.findIndex((el: any) => el._id === data.caseId || el.baseCaseId === data.caseId);
            if (idx === -1) return;

            this.cases[idx][data.type][data.lang] = data.value;

            return;
        }

        idx = this.cases.findIndex((el: any) => el._id === data.caseId);
        if (idx === -1) return;

        if (data.type === 'pdf') {
            this.cases[idx][data.type] = data.value;
        } else if (data.type === 'verify') {
            if (!data.lang) return;

            this.cases[idx][data.type][data.lang] = data.value;
        } else if (data.type === 'ventilation_device') {
            this.cases[idx][data.type] = data.value;

            if (!data.value && this.openTab === 'ventilation') {
                this.openTab = "main";
            }
        }
    }

    setModalCopyCase(data: any) {
        this.modalCopyCase = data;
    }

    setDrugType(data: any) {
        this.drug_type = data;
    }

    setLocation(data: any) {
        this.location = data;
    }

    setWho(data: any) {
        this.who = data;
    }

    setUrinaryColor(data: any) {
        this.urinary_color = data;
    }

    setPupilReaction(data: any) {
        this.pupil_reaction = data;
    }

    setSkinTurgor(data: any) {
        this.skin_turgor = data;
    }

    setSpeech(data: any) {
        this.speech = data;
    }

    setStates(data: any) {
        this.special_states = data;
    }

    setType(data: any) {
        this.type = data;
    }

    setVacutainerTubes(data: any) {
        this.vacutainer_tubes = data;
    }

    setDurationUnit(data: any) {
        this.duration_unit = data;
    }

    setCurrentAudio(data: Assets | null) {
        this.currentAudio = data;
    }

    setParamsGenAudio(data: ParamsGenAudio) {
        this.paramsGenAudio = data;
    }

    setNursingPack(data: any) {
        this.packs = data;
    }

    setCases(data: any) {
        this.cases = data;
    }

    setCategories(data: any) {
        this.categories = data;
    }

    setSelectedCase(data: any) {
        this.selectedCase = data;
    }

    setOpenTab(data: 'main' | 'patient' | 'dialogue' | 'investigation' | 'drug' | 'medication' | 'messages' | 'ventilation') {
        this.openTab = data;
    }

    setCommonOpenTab(data: 'dialogue' | 'main' | 'investigation' | 'who' | 'patient' | 'drug' | '') {
        this.commonOpenTab = data;
    }

    setCaseData(data: any) {
        this.caseData = data;
    }

    setPatientState(data: any) {
        this.patientState = data;
    }

    setDialogue(data: any) {
        this.dialogue = data;
    }

    setInvestigation(data: any) {
        this.investigation = data;
    }

    setDrug(data: any) {
        this.rangeDrug = data;
    }

    setMedication(data: any) {
        this.medication = data;
    }

    updateMainData(data: any) {
        if (!this.caseData || this.caseData._id !== data.dataId || this.selectedCase?._id !== data.caseId) return;

        if (data.type === 'student_objectives' || data.type === 'medical_pad' || data.type === 'patient_name' || data.type === 'blue_band' || data.type === 'red_band' || data.type === 'description') {
            if (!data.lang) return;

            this.caseData[data.type][data.lang] = data.value;
            return;
        }

        this.caseData[data.type] = data.value;
    }


    updateMedication(data: any) {
        if (!this.medication) return;
        if (this.selectedCase?._id !== data.caseId) return;

        if (data.isDosage) {
            const idx = this.medication[data.medicineType].findIndex((el: any) => el._id === data.medicineId);
            if (idx === -1) return;

            if (data.method === 'insert') {
                this.medication[data.medicineType][idx].dosage.push(data.value);
            } else {
                const idxDosage = this.medication[data.medicineType][idx].dosage.findIndex((el: any) => el._id === data.dosageId);
                if (idxDosage === -1) return;

                if (data.method === 'delete') {
                    this.medication[data.medicineType][idx].dosage.splice(idxDosage, 1);
                } else {
                    this.medication[data.medicineType][idx].dosage[idxDosage][data.type] = data.value;
                }
            }
        } else {
            if (data.method === 'insert') {
                this.medication[data.medicineType].push(data.value);
            } else {
                const idx = this.medication[data.medicineType].findIndex((el: any) => el._id === data.medicineId);
                if (idx === -1) return;

                if (data.method === 'update') {
                    if (data.type === 'name') {
                        if (!data.lang) return;

                        this.medication[data.medicineType][idx].name[data.lang] = data.value;
                    } else {
                        this.medication[data.medicineType][idx][data.type] = data.value;
                    }
                } else if (data.method === 'delete') {
                    this.medication[data.medicineType].splice(idx, 1);
                }
            }
        }
    }

    updateMedicationDrug(data: any) {
        if (!this.medication || !this.rangeDrug) return;
        if (this.selectedCase?._id !== data.caseId) return;

        if (!data.isDosage) {
            if (data.method === 'insert') {
                this.medication[data.medicineType].push(data.value);
            } else {
                const idx = this.medication[data.medicineType].findIndex((el: any) => el._id === data.medicineId);
                if (idx === -1) return;

                if (data.method === 'update') {
                    if (data.type === 'name') {
                        if (!data.lang) return;

                        this.medication[data.medicineType][idx].name[data.lang] = data.value;
                    }
                } else if (data.method === 'delete') {
                    this.medication[data.medicineType].splice(idx, 1);

                    for (let i = 0; i < this.rangeDrug.length; ++i) {
                        if (this.rangeDrug[i].medication === data.medicineId) {
                            this.rangeDrug[i].medication = '';
                        }
                    }
                }
            }
        }
    }

    updateLocation(data: any) {
        if (!this.location) return;

        if (data.method === 'insert') {
            this.location.push(data.value);
        } else {
            const idx = this.location.findIndex((el: any) => el._id === data.locationId);
            if (idx === -1) return;

            if (data.method === 'update') {
                if (data.type === 'name') {
                    if (!data.lang) return;

                    this.location[idx].name[data.lang] = data.value;
                } else {
                    this.location[idx][data.type] = data.value;
                }
            } else if (data.method === 'delete') {
                this.location.splice(idx, 1);
            }
        }
    }

    updateType(data: any) {
        if (!this.type) return;

        if (data.method === 'insert') {
            this.type.push(data.value);
        } else {
            const idx = this.type.findIndex((el: any) => el._id === data.typeId);
            if (idx === -1) return;

            if (data.method === 'update') {
                if (!data.lang) return;

                this.type[idx].name[data.lang] = data.value;
            } else if (data.method === 'delete') {
                this.type.splice(idx, 1);
            }
        }
    }

    updateWho(data: any) {
        if (!this.who) return;

        if (data.method === 'insert') {
            this.who.push(data.value);
        } else {
            const idx = this.who.findIndex((el: any) => el._id === data.id);
            if (idx === -1) return;

            if (data.method === 'update') {
                if (!data.lang) return;

                this.who[idx].name[data.lang] = data.value;
            } else if (data.method === 'delete') {
                this.who.splice(idx, 1);
            }
        }
    }

    updateVacutainerTubes(data: any) {
        if (!this.vacutainer_tubes) return;

        if (data.method === 'insert') {
            this.vacutainer_tubes.push(data.value);
        } else {
            const idx = this.vacutainer_tubes.findIndex((el: any) => el._id === data.id);
            if (idx === -1) return;

            if (data.method === 'update') {
                if (!data.lang) return;

                this.vacutainer_tubes[idx].name[data.lang] = data.value;
            } else if (data.method === 'delete') {
                this.vacutainer_tubes.splice(idx, 1);
            }
        }
    }

    updateDurationUnit(data: any) {
        if (!this.duration_unit) return;

        if (data.method === 'insert') {
            this.duration_unit.push(data.value);
        } else {
            const idx = this.duration_unit.findIndex((el: any) => el._id === data.id);
            if (idx === -1) return;

            if (data.method === 'update') {
                if (!data.lang) return;

                this.duration_unit[idx].name[data.lang] = data.value;
            } else if (data.method === 'delete') {
                this.duration_unit.splice(idx, 1);
            }
        }
    }

    updateUrinaryColor(data: any) {
        if (!this.urinary_color) return;

        if (data.method === 'insert') {
            this.urinary_color.push(data.value);
        } else {
            const idx = this.urinary_color.findIndex((el: any) => el._id === data.id);
            if (idx === -1) return;

            if (data.method === 'update') {
                if (!data.lang) return;

                this.urinary_color[idx].name[data.lang] = data.value;
            } else if (data.method === 'delete') {
                this.urinary_color.splice(idx, 1);
            }
        }
    }

    updatePupilReaction(data: any) {
        if (!this.pupil_reaction) return;

        if (data.method === 'insert') {
            this.pupil_reaction.push(data.value);
        } else {
            const idx = this.pupil_reaction.findIndex((el: any) => el._id === data.id);
            if (idx === -1) return;

            if (data.method === 'update') {
                if (!data.lang) return;

                this.pupil_reaction[idx].name[data.lang] = data.value;
            } else if (data.method === 'delete') {
                this.pupil_reaction.splice(idx, 1);
            }
        }
    }

    updateSkinTurgor(data: any) {
        if (!this.skin_turgor) return;

        if (data.method === 'insert') {
            this.skin_turgor.push(data.value);
        } else {
            const idx = this.skin_turgor.findIndex((el: any) => el._id === data.id);
            if (idx === -1) return;

            if (data.method === 'update') {
                if (!data.lang) return;

                this.skin_turgor[idx].name[data.lang] = data.value;
            } else if (data.method === 'delete') {
                this.skin_turgor.splice(idx, 1);
            }
        }
    }

    updateSpeech(data: any) {
        if (!this.speech) return;

        if (data.method === 'insert') {
            this.speech.push(data.value);
        } else {
            const idx = this.speech.findIndex((el: any) => el._id === data.id);
            if (idx === -1) return;

            if (data.method === 'update') {
                if (!data.lang) return;

                this.speech[idx].name[data.lang] = data.value;
            } else if (data.method === 'delete') {
                this.speech.splice(idx, 1);
            }
        }
    }

    updateStates(data: any) {
        if (!this.special_states) return;

        if (data.method === 'insert') {
            this.special_states.push(data.value);
        } else {
            const idx = this.special_states.findIndex((el: any) => el._id === data.id);
            if (idx === -1) return;

            if (data.method === 'update') {
                if (data.type === 'name') {
                    if (!data.lang) return;

                    this.special_states[idx].name[data.lang] = data.value;
                } else if (data.type === 'index') {
                    this.special_states[idx].index = data.value;
                }
            } else if (data.method === 'delete') {
                this.special_states.splice(idx, 1);
            }
        }
    }

    updateMessage(data: any) {
        if (!this.messages || !this.selectedCase || this.selectedCase._id !== data.caseId) return;

        if (data.method === 'insert') {
            this.messages.push(data.value);
        } else {
            const idx = this.messages.findIndex((el: any) => el._id === data.messageId);
            if (idx === -1) return;

            if (data.method === 'update') {
                if (data.type === 'message') {
                    if (!data.lang) return;

                    this.messages[idx][data.type][data.lang] = data.value;
                    return;
                }

                this.messages[idx][data.type] = data.value;

                return;
            } else if (data.method === 'delete') {
                this.messages.splice(idx, 1);
            }
        }
    }

    updatePatientState(data: any) {
        if (!this.patientState || !this.selectedCase || this.selectedCase._id !== data.caseId) return;

        if (data.method === 'insert') {
            this.patientState.push(data.value);
        } else {
            const idx = this.patientState.findIndex((el: any) => el._id === data.patientId);
            if (idx === -1) return;

            if (data.method === 'update') {
                if (data.type === 'apex_palpation') {
                    if (!data.lang) return;

                    this.patientState[idx][data.type][data.lang] = data.value;
                    return;
                }

                if (data.subtype) {
                    this.patientState[idx][data.type][data.subtype] = data.value;
                    return;
                }

                this.patientState[idx][data.type] = data.value;

                return;
            }

            if (data.method === 'delete') {
                this.patientState.splice(idx, 1);
            }
        }
    }

    updateNursingDialogue(data: any) {
        if (!this.dialogue) return;
        if (data.caseId !== 'common' && (!this.selectedCase || this.selectedCase._id !== data.caseId)) return;

        if (data.method === 'insert') {
            this.dialogue.push(data.value);
        } else {
            const idx = this.dialogue.findIndex((el: any) => el._id === data.dialogueId);
            if (idx === -1) return;

            if (data.method === 'update') {
                if (data.type === 'line' || data.type === 'question' || data.type === 'voice_text') {
                    if (!data.lang) return;

                    this.dialogue[idx][data.type][data.lang] = data.value;
                    return;
                }

                this.dialogue[idx][data.type] = data.value;
                return;
            }

            if (data.method === 'delete') {
                this.dialogue.splice(idx, 1);
                return;
            }

            if (data.method === 'generate/audio') {
                if (!data.lang) return;

                this.dialogue[idx].sound[data.lang] = data.value;

                if (data.text) {
                    this.dialogue[idx].voice_text[data.lang] = data.text;
                }

                return;
            }

            if (data.method === 'delete/audio') {
                if (!data.lang) return;

                this.dialogue[idx].sound[data.lang] = data.value;
            }
        }
    }

    updateNursingInvestigation(data: any) {
        if (!this.investigation || !this.selectedCase || this.selectedCase._id !== data.caseId) return;

        if (data.method === 'insert') {
            this.investigation.push(data.value);
        } else {
            const idx = this.investigation.findIndex((el: any) => el._id === data.investigationId);
            if (idx === -1) return;

            if (data.method === 'update') {
                if (data.type === 'result_text' || data.type === 'lab') {
                    if (!data.lang) return;

                    this.investigation[idx][data.type][data.lang] = data.value;
                    return;
                }

                this.investigation[idx][data.type] = data.value;
                return;
            }

            if (data.method === 'delete') {
                this.investigation.splice(idx, 1);
            }
        }
    }

    updateNursingTypeDrug(data: any) {
        if (!this.drug_type) return;

        if (data.method === 'update') {
            const idx = this.drug_type.findIndex((el: any) => el._id === data.id);
            if (idx === -1) return;

            if (data.type === 'name') {
                if (!data.lang) return;

                this.drug_type[idx].name[data.lang] = data.value;
            } else {
                this.drug_type[idx][data.type] = data.value;
            }
        }
    }

    updateNursingDrugRange(data: any) {
        if (!this.rangeDrug || !this.selectedCase || this.selectedCase._id !== data.caseId) return;

        if (data.method === 'insert/set') {
            this.rangeDrug.push(data.value);
        } else if (data.method === 'delete/set') {
            this.rangeDrug = this.rangeDrug.filter((el: any) => el._id !== data.rangeSetId);
        } else {
            const idx = this.rangeDrug.findIndex((el: any) => el._id === data.rangeSetId);
            if (idx === -1) return;

            if (data.method === 'update/set') {
                this.rangeDrug[idx].range_set = data.value;

                return;
            } else if (data.method === 'insert') {
                this.rangeDrug[idx].ranges_sets.push(data.value);

                return;
            } else if (data.method === 'delete') {
                this.rangeDrug[idx].ranges_sets = this.rangeDrug[idx].ranges_sets.filter((el: any) => el._id !== data.rangeId);

                return;
            }

            const idxDrug = this.rangeDrug[idx].ranges_sets.findIndex((el: any) => el._id === data.rangeId);
            if (idxDrug === -1) return;

            this.rangeDrug[idx].ranges_sets[idxDrug][data.type] = data.value;

            if (data.type === 'type') {
                this.rangeDrug[idx].ranges_sets[idxDrug].way = '';
                this.rangeDrug[idx].ranges_sets[idxDrug].medication = '';
                this.rangeDrug[idx].ranges_sets[idxDrug].fluid = '';
                this.rangeDrug[idx].ranges_sets[idxDrug].dosage_min = -1;
                this.rangeDrug[idx].ranges_sets[idxDrug].dosage_max = -1;
                this.rangeDrug[idx].ranges_sets[idxDrug].dosage_unit = '';
                this.rangeDrug[idx].ranges_sets[idxDrug].rate_min = -1;
                this.rangeDrug[idx].ranges_sets[idxDrug].rate_max = -1;
                this.rangeDrug[idx].ranges_sets[idxDrug].duration_min_min = -1;
                this.rangeDrug[idx].ranges_sets[idxDrug].duration_max_min = -1;
            }

            if (data.type === 'way') {
                this.rangeDrug[idx].ranges_sets[idxDrug].medication = '';
            }
        }
    }

    updateNursingVentilation(data: any) {
        if (!this.ventilation || !this.selectedCase || this.selectedCase._id !== data.caseId) return;

        if (data.method === 'insert/set') {
            this.ventilation.push(data.value);
        } else if (data.method === 'delete/set') {
            this.ventilation = this.ventilation.filter((el: any) => el._id !== data.rangeSetId);
        } else {
            const idx = this.ventilation.findIndex((el: any) => el._id === data.rangeSetId);
            if (idx === -1) return;

            if (data.method === 'update/set') {
                this.ventilation[idx].range_set = data.value;

                return;
            } else if (data.method === 'insert') {
                this.ventilation[idx].ventilation_modes.push(data.value);

                return;
            } else if (data.method === 'delete') {
                this.ventilation[idx].ventilation_modes = this.ventilation[idx].ventilation_modes.filter((el: any) => el._id !== data.ventilationId);

                return;
            }

            const idxVentilation = this.ventilation[idx].ventilation_modes.findIndex((el: any) => el._id === data.ventilationId);
            if (idxVentilation === -1) return;

            this.ventilation[idx].ventilation_modes[idxVentilation][data.type] = data.value;
        }
    }

    switchingNursingCase(newCase: any, oldCaseId: string, flag?: boolean) {
        if (!this.cases) return;

        this.cases = this.cases.map((el: any) => el._id === oldCaseId ? newCase : el);

        if (flag) {
            this.setSelectedCase(newCase);
        }
    }

    updateSwitchNursingCase(data: any) {
        if (!this.cases) return;

        this.cases = this.cases.map((el: any) => el._id === data.caseId ? data.value : el);

        if (this.selectedCase?._id === data.caseId) {
            this.selectedCase = null;
        }
    }

    updateCaseErrorNursing(data: any) {
        if (!this.errors?.[data.caseId]) return;


        if (data.tab === 'dialogue') {
            if (data.caseError) {
                if (data.caseError.all) this.errors[data.caseId].all += data.caseError.all;
                if (data.caseError.dialogueAll) this.errors[data.caseId].dialogueAll += data.caseError.dialogueAll;
            }

            if (data.caseErrorLang) {
                for (const lang in data.caseErrorLang.allLang) {
                    this.errors[data.caseId].allLang[lang] += data.caseErrorLang.allLang[lang];
                    this.errors[data.caseId].dialogueAllLang[lang] += data.caseErrorLang.dialogueAllLang[lang];
                }
            }

            if (!this.selectedCase || this.selectedCase._id !== data.caseId || !this.dialogue_errors) return;

            if (data.type === 'insert') {
                this.dialogue_errors[data.elemId] = data.error;

                return;
            }

            if (!this.dialogue_errors?.[data.elemId]) return;

            for (const key in data.tabError) {
                this.dialogue_errors[data.elemId][key] = data.tabError[key];
            }

            if (data.tabErrorLang) {
                for (const key in data.tabErrorLang) {
                    if (!this.dialogue_errors[data.elemId][key]) {
                        this.dialogue_errors[data.elemId][key] = data.tabErrorLang[key];

                        continue;
                    }

                    this.dialogue_errors[data.elemId][key] = {...this.dialogue_errors[data.elemId][key], ...data.tabErrorLang[key]}
                }
            }
        } else if (data.tab === 'investigation') {
            if (data.caseError) {
                if (data.caseError.all) this.errors[data.caseId].all += data.caseError.all;
                if (data.caseError.investigationAll) this.errors[data.caseId].investigationAll += data.caseError.investigationAll;
            }

            if (data.caseErrorLang) {
                for (const lang in data.caseErrorLang.allLang) {
                    this.errors[data.caseId].allLang[lang] += data.caseErrorLang.allLang[lang];
                    this.errors[data.caseId].investigationAllLang[lang] += data.caseErrorLang.investigationAllLang[lang];
                }
            }

            if (!this.selectedCase || this.selectedCase._id !== data.caseId || !this.investigation_errors) return;

            if (data.type === 'insert') {
                this.investigation_errors[data.elemId] = data.error;

                return;
            }

            if (!this.investigation_errors?.[data.elemId]) return;

            if (data.tabError) {
                for (const key in data.tabError) {
                    this.investigation_errors[data.elemId][key] = data.tabError[key];
                }
            }

            if (data.tabErrorLang) {
                for (const key in data.tabErrorLang) {
                    if (!this.investigation_errors[data.elemId][key]) {
                        this.investigation_errors[data.elemId][key] = data.tabErrorLang[key];

                        continue;
                    }

                    this.investigation_errors[data.elemId][key] = {...this.investigation_errors[data.elemId][key], ...data.tabErrorLang[key]}
                }
            }
        } else if (data.tab === 'range_drugs') {
            if (data.caseError) {
                if (data.caseError.all) this.errors[data.caseId].all += data.caseError.all;
                if (data.caseError.range_drugsAll) this.errors[data.caseId].range_drugsAll += data.caseError.range_drugsAll;
            }

            if (!this.selectedCase || this.selectedCase._id !== data.caseId || !this.range_drugs_errors) return;

            if (data.type === 'insert') {
                this.range_drugs_errors[data.elemId] = data.error;

                return;
            }

            if (!this.range_drugs_errors?.[data.elemId]) return;

            if (data.tabError) {
                for (const key in data.tabError) {
                    this.range_drugs_errors[data.elemId][key] = data.tabError[key];
                }
            }
        } else if (data.tab === 'ventilation') {
            if (data.caseError) {
                if (data.caseError.all) this.errors[data.caseId].all += data.caseError.all;
                if (data.caseError.ventilationAll) this.errors[data.caseId].ventilationAll += data.caseError.ventilationAll;
            }

            if (!this.selectedCase || this.selectedCase._id !== data.caseId || !this.ventilation_errors) return;

            if (data.type === 'insert') {
                this.ventilation_errors[data.elemId] = data.error;

                return;
            }

            if (!this.ventilation_errors?.[data.elemId]) return;

            if (data.tabError) {
                for (const key in data.tabError) {
                    this.ventilation_errors[data.elemId][key] = data.tabError[key];
                }
            }
        }
    }

    updateVerify(data: any) {
        if (!this.cases?.length) return;

        const changeVer = (idx: number) => {
            if (data.lang) {
                this.cases[idx].verify[data.lang] = false;
            } else {
                const obj: any = {};
                for (const lang in this.cases[idx].verify) {
                    obj[lang] = false;
                }
                this.cases[idx].verify = obj;
            }
        }

        if (data.caseId) {
            const idx = this.cases.findIndex((el: any) => el._id === data.caseId);
            if (idx !== -1) {
                changeVer(idx);
            }
        } else {
            for (let i = 0; this.cases.length; ++i) {
                changeVer(i);
            }
        }
    }
}