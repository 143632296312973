import {makeAutoObservable} from 'mobx';
import {
    Classes,
    ErrorInterface,
    IContextMenu,
    IFileParams, IProfileCases, IProfileCategory,
    IProfileFilter, ISearchData,
    LangLocalization,
    ListFilesEl,
    ModalAcceptParams,
    Pack, PackParams, SubClasses, UsersInterface
} from '../interface';
import {DEFAULT_EMPTY_LANGUAGES_VALUES, LANGUAGES} from "../Utils";

export default class CoreStore {
    //Текущий язык веб-сервиса
    localization = 'en';
    //список допуступных языков
    listLanguages: Array<string> = ['en'];
    //параметры для модалки ошибок(видимость, заголовок, текст)
    errorParams: ErrorInterface = {
        errorShow: false,
        errorTitle: '',
        errorText: ''
    };
    //параметры для загрузки фото/аудио
    fileParams: IFileParams | null = null;
    //отвечает за отображение иконки меню в режиме планшета
    showLeftMenu: boolean = true;
    //Режим планшета
    tabMode: boolean = false;
    //Режим мобилки
    mobileMode: boolean = false;
    //паки или профили
    pack: Pack
    //модалка веб-сокета
    showAcceptModal: boolean = false;
    //функция на закрытие модалки
    hideAccept: Function
    //просмотрена ли модалка веб-сокета
    socketAcceptModalViewed: boolean = false;
    //параметры модалки
    modalAcceptParams: ModalAcceptParams = {headerText: null, bodyText: null, buttonText: null, secondBtn: null}
    //модалка загрузки
    loading: boolean = false;
    //Пак для модалки редактирования профиля
    packCases: Array<string>
    //контекстное меню
    contextMenu: IContextMenu = {
        id: '',
        type: '',
        name: {...DEFAULT_EMPTY_LANGUAGES_VALUES},
        show: false,
        x: '',
        y: '',
        anyInfo: null
    }
    //флаг для отображения модалки
    showFullScreen: boolean = false;
    //ссылка изображения
    hrefFullScreen: string = '';
    //модалка, когда не отвечает сервер
    modalTechnical: boolean = false;
    //параметры фильтра для профилей
    profileFilter: IProfileFilter = {
        category: [],
        author: [],
        pack: [],
        caseSearch: ''
    }
    //Общее кол-во элементов
    vocabularyCount: number
    //Поисковик по словарю
    searchVoc: LangLocalization
    //Список файлов
    listFiles: Array<ListFilesEl> = [];
    //Searching
    searchData: ISearchData;
    //Profile page cases
    profileCases: IProfileCases = {
        original: [],
        international: [],
        nursing: []
    }
    profileCategory: IProfileCategory = {
        original: [],
        international: [],
        nursing: []
    }
    nursingPack: any;
    selectedPack: PackParams | null = null;


    constructor() {
        makeAutoObservable(this);
    }

    setNursingPack(data: any) {
        this.nursingPack = data;
    }

    setSelectedPack(data: PackParams) {
        this.selectedPack = data;
    }

    resetProfilePage() {
        this.profileCases = {
            original: [],
            international: [],
            nursing: []
        }
        this.profileCategory = {
            original: [],
            international: [],
            nursing: []
        }
        this.nursingPack = null;
    }

    setProfileCases(key: string, data: SubClasses[]) {
        this.profileCases[key as keyof IProfileCases] = data;
    }

    updateProfileCases(key: string, caseId: string) {
        if (!this.profileCases?.[key as keyof IProfileCases]) return;

        const idx = this.profileCases[key as keyof IProfileCases].findIndex((el: any) => el._id === caseId);
        if (idx !== -1) {
            this.profileCases[key as keyof IProfileCases].splice(idx, 1);
        }
    }

    setProfileCategory(key: string, data: Classes[]) {
        this.profileCategory[key as keyof IProfileCategory] = data;
    }

    updateProfileCategory(data: any) {
        const categories = this.profileCategory[data.db as keyof IProfileCases];

        if (data.type === 'insert') {
            categories.push(data.value);
        } else {
            const idx = categories.findIndex((el: Classes) => el._id === data.categoryId);
            if (idx !== -1) {
                if (data.type === 'delete') {
                    categories.splice(idx, 1);
                } else if (data.type === 'update') {
                    categories[idx].name[data.lang] = data.value;
                } else if (data.type === 'text') {
                    categories[idx].text[data.lang] = data.value;
                }
            }
        }
    }

    setSearchData(data: ISearchData) {
        this.searchData = data;
    }

    setListFiles(data: Array<ListFilesEl>) {
        this.listFiles = data;
    }

    updateListFiles(data: string) {
        const idx = this.listFiles.findIndex((el: ListFilesEl) => {
            return el._id === data
        });
        if (idx !== -1) {
            this.listFiles.splice(idx, 1);
        }
    }

    updateFileDesc({mediaId, description}: { mediaId: string, description: string }) {
        const idx = this.listFiles.findIndex((el: ListFilesEl) => {
            return el._id === mediaId
        });
        if (idx !== -1) {
            this.listFiles[idx].description = description;
        }
    }

    setVocabularyCount(data: number) {
        this.vocabularyCount = data;
    }

    setSearchVoc(data: LangLocalization) {
        this.searchVoc = data;
    }

    setProfileFilter(field: string, data: string[] | string) {
        this.profileFilter[field] = data;
    }

    resetProfileFilter() {
        this.profileFilter = {
            category: [],
            author: [],
            pack: [],
            caseSearch: '',
        }
    }

    //Установка флага для модалки
    setShowAcceptModal(data: boolean) {
        if (!data) this.modalAcceptParams = {headerText: null, bodyText: null, buttonText: null, secondBtn: null};
        this.showAcceptModal = data;
    }

    //Установка функции закрытия
    setHideAccept(data: Function) {
        this.hideAccept = data;
    }

    //Установка параметры модалки
    setModalAcceptParams(data: ModalAcceptParams) {
        this.modalAcceptParams = data;
    }

    //Установка флага видимости модалки
    setSocketAcceptModalViewed(data: boolean) {
        this.socketAcceptModalViewed = data;
    }

    //Установка данных для загрузки фото\аудио
    setFileParams(data: IFileParams | null) {
        this.fileParams = data;
    }

    //Установка языка
    setLocalization(lang: string) {
        this.localization = lang;
    }

    setLanguages(user?: UsersInterface) {
        if (!user) {
            this.listLanguages = ['en'];
        }

        if (user?.role !== 'admin') {
            const set: Set<string> = new Set();
            if (user.languages) {
                for (const lang of user.languages) {
                    set.add(lang);
                }
            }

            if (user.nursingLanguages) {
                for (const lang of user.nursingLanguages) {
                    set.add(lang);
                }
            }

            this.listLanguages = [...set];
        } else {
            this.listLanguages = LANGUAGES;
        }

        this.setLocalization(this.listLanguages[0]);
    }

    //Установка данных для отображения ошибки
    setErrorParams(data: ErrorInterface) {
        this.errorParams = data;
    }

    //Установка данных по пакам\профилям
    setPack(data: Pack) {
        this.pack = data;
    }

    updateDataNursingPack(data: any) {
        if (!this.nursingPack) return;

        if (data.type === 'insert') {
            this.nursingPack.push(data.value);
        } else {
            const idx = this.nursingPack.findIndex((el: PackParams) => el._id === data.packId);
            if (idx === -1) return;

            if (data.type === 'delete') {
                this.nursingPack.splice(idx, 1);
            } else if (data.type === 'changeName') {
                this.nursingPack[idx].name = data.value;
            } else if (data.type === 'caseLimit') {
                this.nursingPack[idx].caseLimit = data.value;
            } else if (data.type === 'addCase') {
                this.nursingPack[idx].cases.push(data.caseId);
            } else if (data.type === 'deleteCase') {
                const idxCase = this.nursingPack[idx].cases.findIndex((el: string) => el === data.caseId);
                if (idxCase !== -1) {
                    this.nursingPack[idx].cases.splice(idxCase, 1);
                }
            }
        }
    }

    updateDataPack(data: any) {
        if (!this.pack?.[data.db]) return;

        if (data.type === 'insert') {
            this.pack[data.db].push(data.value);
        } else {
            const idx = this.pack[data.db].findIndex((el: PackParams) => el._id === data.packId);
            if (idx === -1) return;

            if (data.type === 'copyCases') {
                this.pack[data.db][idx].cases = data.value;
                this.pack[data.db][idx].categories = data.categories;
            } else if (data.type === 'delete') {
                this.pack[data.db].splice(idx, 1);
                if (this.selectedPack?._id === data.packId) {
                    this.selectedPack = null;
                }
            } else if (data.type === 'categories') {
                if (!data.checked) {
                    const num = this.pack[data.db][idx].categories.indexOf(data.categoryId);
                    if (num === -1) return;

                    this.pack[data.db][idx].categories.splice(num, 1);
                } else {
                    this.pack[data.db][idx].categories.push(data.categoryId);
                }
            }
        }
    }

    updatePackData(data: any) {
        if (this.pack && this.pack[data.std]) {
            const idxPack = this.pack[data.std].findIndex((el: PackParams) => el._id === data.packId);
            if (idxPack !== -1) {
                if (data.type === 'addCase') {
                    this.pack[data.std][idxPack].cases.push(data.caseId);
                } else if (data.type === 'deleteCase') {
                    const idx = this.pack[data.std][idxPack].cases.findIndex((el: string) => el === data.caseId);
                    if (idx !== -1) {
                        this.pack[data.std][idxPack].cases.splice(idx, 1);
                    }
                } else if (data.type === 'changeName') {
                    this.pack[data.std][idxPack].name = data.namePack;
                } else if (data.type === 'caseLimit') {
                    this.pack[data.std][idxPack].caseLimit = data.caseLimit;
                }
            }
        }
    }

    //Изменение флага видимости меню
    changeVisibleMenu(bool?: boolean) {
        if (typeof bool === 'boolean') this.showLeftMenu = bool;
        else this.showLeftMenu = !this.showLeftMenu;
    }

    //Изменение режима
    changeTabMode(data: boolean) {
        this.tabMode = data;
    }

    //Изменение режима
    changeMobileMode(data: boolean) {
        this.mobileMode = data;
    }

    //Зактрытие модалки ошибки.
    closeErrorModal() {
        this.errorParams.errorShow = !this.errorParams.errorShow;
    }

    //Изменение флага состояния загрузки
    setLoading(data: boolean) {
        this.loading = data;
    }

    //Установка данных по кейсам в паке
    setPackCases(data: Array<string>) {
        this.packCases = data;
    }

    setContextMenu(params: IContextMenu) {
        this.contextMenu = params;
    }

    closeContextMenu() {
        this.contextMenu = {
            id: '',
            type: '',
            name: DEFAULT_EMPTY_LANGUAGES_VALUES,
            show: false,
            x: '',
            y: '',
            anyInfo: null
        }
    }

    //установка параметров для открытия изображения на весь экран
    setParamsFullScreen(flag: boolean, href: string) {
        this.showFullScreen = flag;
        this.hrefFullScreen = href;
    }

    setModalTechnical(data: boolean) {
        this.modalTechnical = data;
    }
}
