import {useContext, useEffect, useRef} from 'react';
import {Context} from "../index";
import {API_SOCKET_URl, CASE_TABS} from "../Utils";
import {
    getActiveCases,
    getAuscultation,
    getCaseById,
    getDiagnosis,
    getDialogue,
    getExamination,
    getExaminationTabCommon,
    getExaminationTabs,
    getInstruments,
    getPalpation,
    getPercussion,
    getTreatment,
    getTreatmentCondition,
    getTreatmentDiet,
    getTreatmentDuration,
    getTreatmentForm,
    getTreatmentFrequency,
    getTreatmentHospitalization,
    getTreatmentInjection,
    getTreatmentMedicine,
    getVisual
} from "../http/Outpatient";
import {lang} from "../Utils/lang";
import {getPack} from "../http/core";
import {updateWSID} from "../http";
import {
    getCaseData, getDrugRange,
    getInvestigation,
    getNursingDialogue,
    getNursingMedication, getNursingMessages,
    getPatientState, getVentilation
} from "../http/Nursing";

interface WebSocketAnswer {
    type: string
    update?: string
    caseId?: string
    data?: any
    check?: string
    packId?: string
    wsId: string
}

function useSocket() {
    const {ambulant, core_store, user, common, nursingStore} = useContext(Context);
    const socket: any = useRef();
    const socketConnect: any = useRef();

    //подключение веб-сокета
    const connect = () => {
        socketConnect.current = false;
        socket.current = new WebSocket(API_SOCKET_URl);

        socket.current.onopen = function () {
            socketConnect.current = true;
        }

        socket.current.onclose = function () {
            connect();

            /*setTimeout(() => {
                if (!socketConnect.current && !core_store.socketAcceptModalViewed) {
                    core_store.setModalAcceptParams({
                        bodyText: lang.info.core_47,
                        headerText: lang.errors.core_8,
                        buttonText: lang.info.core_48,
                    });
                    core_store.setHideAccept((arg: boolean) => {
                        if (!arg) core_store.setSocketAcceptModalViewed(true);
                        core_store.setShowAcceptModal(false);
                    })
                    core_store.setShowAcceptModal(true);
                }
            }, 5000);*/

            socketConnect.current = false;
        };

        socket.current.onmessage = function (event: any) {
            const message: WebSocketAnswer = JSON.parse(event.data);
            const currentStd = localStorage.getItem('std');

            if (message && message.type && message.type === 'update' && message.update && message.data) {
                switch (message.update) {
                    /* Амбулаторка */
                    case 'categories':
                        if (window.location.pathname === '/outpatient_appointment') {
                            if (currentStd === message.data.db) {
                                ambulant.updateCategoryData(message.data);
                            }
                        } else if (window.location.pathname === '/profile') {
                            core_store.updateProfileCategory(message.data);
                        }
                        break;
                    case 'cases':
                        if (window.location.pathname !== '/outpatient_appointment') return;
                        if (currentStd !== message.data.db) return;
                        if (localStorage.getItem('packId') !== message.data.packId) return;

                        getActiveCases().then(({data}) => {
                            if (data) ambulant.setSubClasses(data);
                        });
                        break;
                    case 'case/error':
                        if (currentStd === message.data.db) {
                            ambulant.updateCaseError(message.data);
                        }
                        break;
                    case 'case/error/nursing':
                        if (window.location.pathname === '/nursing') {
                            nursingStore.updateCaseErrorNursing(message.data);
                        }
                        break;
                    case 'case/switch':
                        if (currentStd === message.data.db && localStorage.getItem('packId') === message.data.packId && window.location.pathname.indexOf('/outpatient_appointment') !== -1) {
                            ambulant.updateSwitchCase(message.data);
                        }
                        break;
                    case 'nursing/switch':
                        if (message.data.ws !== user.wsId && localStorage.getItem('nursingPackId') === message.data.packId && window.location.pathname === '/nursing') {
                            nursingStore.updateSwitchNursingCase(message.data);
                        }
                        break;
                    case 'case/copy':
                        const curPackId = localStorage.getItem('packId');
                        if (currentStd === message.data.db && (curPackId === message.data.packId || curPackId === 'all') && window.location.pathname.indexOf('/outpatient_appointment') !== -1) {
                            ambulant.copyCase(message.data.value);
                        }
                        break;
                    case 'case/copy/nursing':
                        const currentPackId = localStorage.getItem('nursingPackId');
                        if ((currentPackId === message.data.packId || currentPackId === 'all') && window.location.pathname === '/nursing') {
                            nursingStore.copyCase(message.data.value);
                        }
                        break;
                    case 'nursing/case':
                        if (window.location.pathname === '/nursing') {
                            nursingStore.updateNursingCase(message.data);
                        }
                        break;
                    case 'case/name':
                        if (currentStd === message.data.db && window.location.pathname.indexOf('/outpatient_appointment') !== -1) {
                            ambulant.updateCaseName(message.data);
                        }
                        break;
                    case 'case/delete':
                        if (currentStd === message.data.db && window.location.pathname.indexOf('/outpatient_appointment') !== -1) {
                            ambulant.deleteCase(message.data.caseId);
                        }
                        break;
                    case 'case/delete/nursing':
                        if (window.location.pathname === '/nursing') {
                            nursingStore.deleteNursingCase(message.data.caseId);
                        }
                        if (window.location.pathname === '/profile') {
                            core_store.updateProfileCases('nursing', message.data.caseId);
                        }
                        break;
                    case 'cases/tab':
                        if ((new URL(document.URL)).pathname.indexOf('outpatient_appointment') !== -1) {
                            for (let i = 0; i < message.data.caseIds.length; ++i) {
                                if (ambulant.selectedSubMenuId === message.data.caseIds[i]) {
                                    if (message.data.tab === 'inspection') {
                                        if (message.data.subTab === 'instruments') {
                                            getInstruments(ambulant.selectedSubMenuId).then(r => {
                                                if (r) {
                                                    ambulant.setInstruments(r.data);
                                                } else throw new Error(lang.errors.outpatient_15('Instruments.')[core_store.localization]);
                                            });
                                        } else {
                                            getAuscultation(ambulant.selectedSubMenuId).then(r => {
                                                if (r) {
                                                    ambulant.setAuscultation(r.data);
                                                } else throw new Error(lang.errors.outpatient_15('Auscultation.')[core_store.localization]);
                                            });
                                            getPercussion(ambulant.selectedSubMenuId).then(({data}) => {
                                                if (data) {
                                                    ambulant.setPercussion(data);
                                                } else throw new Error(lang.errors.outpatient_15('Percussion.')[core_store.localization]);
                                            });
                                        }
                                    } else if (message.data.tab === 'examination') {
                                        getExamination(ambulant.selectedSubMenuId).then((r) => {
                                            if (r) {
                                                ambulant.setExamination(r.data);
                                            } else throw new Error(lang.errors.outpatient_15('Examination.')[core_store.localization]);
                                        });
                                    }
                                    break;
                                }
                            }
                            getActiveCases().then(r => {
                                ambulant.setSubClasses(r.data);
                            }).catch(e => console.log(e));
                        }
                        break;
                    case 'media/delete':
                        if (core_store.listFiles && core_store.listFiles.length) {
                            core_store.updateListFiles(message.data);
                        }
                        break;
                    case 'media/desc':
                        if (core_store.listFiles && core_store.listFiles.length) {
                            core_store.updateFileDesc(message.data);
                        }
                        break;
                    case 'case/verify':
                        if (message.data.db === 'nursing') {
                            nursingStore.updateVerify(message.data);
                        } else if (currentStd === message.data.db) {
                            ambulant.setCaseVerify(message.data);
                        }
                        break;
                    case 'cases/verify':
                        if (currentStd === message.data.db) {
                            ambulant.updateCasesVerify(message.data);
                        }
                        break;
                    case 'verify':
                        if (currentStd === message.data.db) {
                            ambulant.updateCaseVerify(message.data);
                        }
                        break;
                    case 'profile/data':
                        if (window.location.pathname.indexOf('/profile') !== -1) {
                            core_store.updatePackData(message.data);
                        } else if (window.location.pathname.indexOf('/outpatient_appointment') !== -1) {
                            if (localStorage.getItem('packId') === message.data.packId) {
                                getPack(user.role !== 'admin' ? user.id : '').then(r => {
                                    if (r) core_store.setPack(r.data);
                                })
                                getActiveCases().then(r => {
                                    if (r) ambulant.setSubClasses(r.data);
                                });
                            }
                        }
                        break;
                    case 'jobs':
                        if (ambulant.selectedSubMenuId && ambulant.openTabId === CASE_TABS.PASSPORT) {
                            ambulant.updateJobs(message.data);
                        } else if (window.location.pathname.indexOf('common/passport') !== -1) {
                            common.updateJobsCommon(message.data);
                        }
                        break;
                    case 'inspection/data':
                        if (message.data.caseId && ambulant.selectedSubMenuId === message.data.caseId && ambulant.openTabId === CASE_TABS.INSPECTION) {
                            ambulant.updateInspectionData(message.data);
                        }
                        break;
                    case 'diagnosis/data':
                        if (message.data.caseId && ambulant.selectedSubMenuId === message.data.caseId && ambulant.openTabId === CASE_TABS.DIAGNOSIS) {
                            ambulant.updateDiagnosisData(message.data);
                        }
                        break;
                    case 'dialogue/data':
                        if (currentStd === message.data.db && message.data.caseId && ambulant.selectedSubMenuId === message.data.caseId && ambulant.openTabId === CASE_TABS.DIALOGUE) {
                            if (message.data.type === 'dnd') {
                                if (message.data.ws === user.wsId) return;

                                getDialogue(ambulant.selectedSubMenuId).then(({data}) => {
                                    if (data) ambulant.setDialogue(data);
                                });


                                /*ambulant.dialogueDragDrop({
                                    dialogueId: message.data.dialogueId,
                                    newIndex: message.data.newIndex,
                                    oldIndex: message.data.oldIndex,
                                    dialogue: message.data.dialogue
                                });*/
                            } else {
                                ambulant.updateDialogueData(message.data);
                            }
                        }
                        break;
                    case 'treatment/data':
                        if (message.data.caseId && ambulant.selectedSubMenuId === message.data.caseId && ambulant.openTabId === CASE_TABS.TREATMENT) {
                            ambulant.updateTreatmentData(message.data);
                        }
                        break;
                    case 'treatment/medication':
                        if (message.data.caseId && ambulant.selectedSubMenuId === message.data.caseId && ambulant.openTabId === CASE_TABS.TREATMENT) {
                            ambulant.updateTreatmentMedication(message.data);
                        }
                        break;
                    case 'treatment/common':
                        if (ambulant.selectedSubMenuId && ambulant.openTabId === CASE_TABS.TREATMENT) {
                            if (message.data.type === 'change' || message.data.type === 'insert') {
                                ambulant.updateTreatmentDataFromCommon(message.data);
                            } else if (message.data.type === 'insert' && (message.data.tabName === 'hospitalization' || message.data.tabName === 'diet')) {
                                ambulant.updateTreatmentDataFromCommon(message.data);
                            } else {
                                getTreatment(ambulant.selectedSubMenuId).then(({data}) => {
                                    if (data) ambulant.setTreatment(data);
                                });
                                getTreatmentMedicine().then(({data}) => {
                                    if (data) ambulant.setMedicine(data);
                                });

                                if (message.data.tabName === 'diet') {
                                    getTreatmentDiet().then(({data}) => {
                                        if (data) ambulant.setDiet(data);
                                    });
                                } else if (message.data.tabName === 'hospitalization') {
                                    getTreatmentHospitalization().then(({data}) => {
                                        if (data) ambulant.setHospitalization(data);
                                    });
                                } else if (message.data.tabName === 'frequency') {
                                    getTreatmentFrequency().then(({data}) => {
                                        if (data) ambulant.setFrequency(data);
                                    });
                                } else if (message.data.tabName === 'duration') {
                                    getTreatmentDuration().then(({data}) => {
                                        if (data) ambulant.setDuration(data);
                                    });
                                } else if (message.data.tabName === 'injection') {
                                    getTreatmentInjection().then(({data}) => {
                                        if (data) ambulant.setInjection(data);
                                    });
                                } else if (message.data.tabName === 'condition') {
                                    getTreatmentCondition().then(({data}) => {
                                        if (data) ambulant.setCondition(data);
                                    });
                                } else if (message.data.tabName === 'form') {
                                    getTreatmentForm().then(({data}) => {
                                        if (data) ambulant.setForm(data);
                                    });
                                }
                            }
                        } else if (window.location.pathname.indexOf('common/treatment') !== -1) {
                            common.updateTreatmentDataCommon(message.data);
                        }
                        break;
                    case 'medicine/data':
                        if (window.location.pathname.indexOf('common/medicine') !== -1) {
                            common.updateMedicineDataCommon(message.data);
                        } else if (ambulant.selectedSubMenuId && ambulant.openTabId === CASE_TABS.TREATMENT) {
                            const types = ['addDose', 'deleteDose', 'condition', 'duration', 'injection', 'form', 'frequency'];
                            if (message.data.type === 'delete') {
                                ambulant.updateMedicineDataFromCommon(message.data);
                            } else if (types.indexOf(message.data.type) !== -1) {
                                if (ambulant.treatment && ambulant.treatment.medicines?.length) {
                                    if (ambulant.treatment.medicines.findIndex((el: any) => el.medicine._id === message.data.medicineId) !== -1) {
                                        getTreatment(ambulant.selectedSubMenuId).then(({data}) => {
                                            if (data) ambulant.setTreatment(data);
                                        });
                                        getTreatmentMedicine().then(({data}) => {
                                            if (data) ambulant.setMedicine(data);
                                        });
                                    }
                                }
                            }
                        }
                        break;
                    case 'review/simulator':
                        console.log(message.data, window.location.pathname)
                        if (window.location.pathname === '/outpatient_appointment/common/inspection') {
                            common.updateReviewSimulator(message.data);
                        }
                        break;
                    case 'review':
                        if (ambulant.selectedSubMenuId && ambulant.openTabId === CASE_TABS.INSPECTION) {
                            if (message.data.type !== 'insert') {
                                ambulant.updateInspectionData(message.data);
                            } else {
                                switch (message.data.tabName) {
                                    case 'percussion':
                                        getPercussion(ambulant.selectedSubMenuId).then(({data}) => {
                                            if (data) {
                                                ambulant.setPercussion(data);
                                            }
                                        });
                                        break;
                                    case 'auscultation':
                                        getAuscultation(ambulant.selectedSubMenuId).then(({data}) => {
                                            if (data) {
                                                ambulant.setAuscultation(data);
                                            }
                                        });
                                        break;
                                    case 'palpation':
                                        getPalpation(ambulant.selectedSubMenuId).then(({data}) => {
                                            if (data) {
                                                ambulant.setPalpation(data);
                                            }
                                        });
                                        break;
                                    case 'visual':
                                        getVisual(ambulant.selectedSubMenuId).then(({data}) => {
                                            if (data) {
                                                ambulant.setVisual(data);
                                            }
                                        });
                                        break;
                                    case 'instruments':
                                        getInstruments(ambulant.selectedSubMenuId).then(({data}) => {
                                            if (data) {
                                                ambulant.setInstruments(data);
                                            }
                                        });
                                        break;
                                    default:
                                        break;
                                }
                            }
                            getActiveCases().then(r => {
                                ambulant.setSubClasses(r.data);
                            }).catch(e => console.log(e));
                        } else if (window.location.pathname.indexOf('common/inspection') !== -1) {
                            common.updateReview(message.data);
                        }
                        break;
                    case 'examination/data':
                        if (message.data.caseId && ambulant.selectedSubMenuId === message.data.caseId && ambulant.openTabId === CASE_TABS.EXAMINATION) {
                            ambulant.updateExaminationData(message.data);
                        } else if (message.data.type === 'columnName' && ambulant.selectedSubMenuId && ambulant.openTabId === CASE_TABS.EXAMINATION) {
                            ambulant.updateExaminationData(message.data);
                        }
                        break;
                    case 'passport/data':
                        if (message.data.caseId && ambulant.selectedSubMenuId === message.data.caseId && ambulant.openTabId === CASE_TABS.PASSPORT) {
                            ambulant.updatePassportData(message.data);
                        }
                        break;
                    case 'percussion':
                        if (message.caseId && ambulant.selectedSubMenuId === message.caseId)
                            ambulant.setPercussion(message.data);
                        break;
                    case 'auscultation':
                        if (message.caseId && ambulant.selectedSubMenuId === message.caseId)
                            ambulant.setAuscultation(message.data);
                        break;
                    case 'instruments':
                        if (message.caseId && ambulant.selectedSubMenuId === message.caseId)
                            ambulant.setInstruments(message.data);
                        break;
                    case 'palpation':
                        if (message.caseId && ambulant.selectedSubMenuId === message.caseId)
                            ambulant.setPalpation(message.data);
                        break;
                    case 'visual':
                        if (message.caseId && ambulant.selectedSubMenuId === message.caseId)
                            ambulant.setVisual(message.data);
                        break;
                    case 'diagnosis/replacement':
                        if (message.data.caseId && ambulant.selectedSubMenuId === message.data.caseId) {
                            ambulant.updateDiagnosisReplacement(message.data);
                        }
                        break;
                    case 'disease':
                        if (window.location.pathname.indexOf('common/disease') !== -1 && common.disease) {
                            common.updateDisease({
                                id: message.data.id,
                                lang: message.data.lang,
                                value: message.data.value,
                                parentIds: message.data.parentIds
                            })
                        }
                        break;
                    case 'medicine/category':
                        if (window.location.pathname.indexOf('common/medicine') !== -1) {
                            common.updateMedicineCategory(message.data);
                        }
                        break;
                    case 'treatment/medicine':
                        ambulant.setMedicine(message.data);
                        break;
                    case 'names':
                        if (window.location.pathname.indexOf('common/names') !== -1 && currentStd === message.data.db)
                            common.updateCaseNames(message.data);
                        break;
                    case 'inspection':
                        if (window.location.pathname.indexOf('common/inspection') !== -1)
                            common.setInspectionTab(message.data);
                        break;
                    case 'files/info':
                        ambulant.setListFiles(message.data);
                        break;
                    /* Амбулаторка-конец */
                    /* Home */
                    case 'pack':
                        if (window.location.pathname === '/outpatient_appointment') {
                            if (message.data.type !== 'delete') return;
                            if (currentStd !== message.data.db) return;
                            if (localStorage.getItem('packId') !== message.data.packId) return;

                            core_store.updateDataPack(message.data);
                        } else if (window.location.pathname === '/profile') {
                            core_store.updateDataPack(message.data);
                        }
                        break;
                    case 'nursing/pack':
                        if (window.location.pathname === '/profile' || window.location.pathname === '/users') {
                            core_store.updateDataNursingPack(message.data);
                        }
                        break;
                    case 'user':
                        for (let i = 0; i < message.data.length; i++) {
                            if (message.data[i]._id === user.id) {
                                user.setCurUser(message.data[i]);
                                break;
                            }
                        }
                        user.setUsers(message.data);
                        break;
                    case 'user/info':
                        user.updateUser(message.data);
                        break;
                    case 'examination/tab':
                        if ((message.data.type !== 'categoryName' || message.data.type !== 'insertCategory' || message.data.type !== 'deleteCategory' || message.data.type !== 'tabCategory') && ambulant.selectedSubMenuId && ambulant.openTabId === CASE_TABS.EXAMINATION) {
                            if (message.data.type === 'insertExamTab' || message.data.type === 'changeTabType') {
                                Promise.all([
                                    getExamination(ambulant.selectedSubMenuId).then(({data}) => {
                                        if (data) {
                                            ambulant.setExamination(data);
                                        }
                                    }),
                                    getExaminationTabs().then(({data}) => {
                                        if (data) {
                                            ambulant.setExaminationList(data);
                                        }
                                    })
                                ]).then(() => {
                                    getActiveCases().then(r => {
                                        ambulant.setSubClasses(r.data);
                                    }).catch(e => console.log(e));
                                }).catch(e => console.log(e));
                            } else {
                                ambulant.updateExaminationData(message.data);
                            }
                        } else if (window.location.pathname.indexOf('common/exam') !== -1) {
                            if (message.data.type === 'insertExamTab') {
                                getExaminationTabCommon().then(({data}) => {
                                    if (data) common.setExamTabs(data);
                                });
                            } else {
                                common.updateExamData(message.data);
                            }
                        }
                        break;
                    /* Home-end */
                    case 'passing':
                        if (window.location.pathname.indexOf('passing') !== -1 && message.data.lang === core_store.localization) {
                            ambulant.updateCasePassingText(message.data);
                        }
                        break;
                    case 'nursing/mainData':
                        if (window.location.pathname.indexOf('nursing') !== -1 && (nursingStore.openTab === 'main' || nursingStore.openTab === 'patient')) {
                            nursingStore.updateMainData(message.data);
                        }
                        break;
                    case 'nursing/medicine':
                        if (window.location.pathname.indexOf('nursing') !== -1) {
                            if (nursingStore.openTab === 'medication') {
                                nursingStore.updateMedication(message.data);
                            } else if (nursingStore.openTab === 'drug') {
                                nursingStore.updateMedicationDrug(message.data);
                            }
                        }
                        break;
                    case 'nursing/location':
                        if ((window.location.pathname.indexOf('nursing/common') !== -1 && nursingStore.commonOpenTab === 'main') || (window.location.pathname.indexOf('nursing') !== -1 && nursingStore.openTab === 'main') || window.location.pathname.indexOf('nursing') !== -1 && nursingStore.openTab === 'patient') {
                            nursingStore.updateLocation(message.data);
                        }
                        break;
                    case 'nursing/type':
                        if ((window.location.pathname.indexOf('nursing/common') !== -1 && nursingStore.commonOpenTab === 'main') || (window.location.pathname.indexOf('nursing') !== -1 && nursingStore.openTab === 'main')) {
                            nursingStore.updateType(message.data);
                        }
                        break;
                    case 'nursing/who':
                        if ((window.location.pathname.indexOf('nursing/common') !== -1 && nursingStore.commonOpenTab === 'who') || (window.location.pathname.indexOf('nursing') !== -1 && nursingStore.openTab === 'dialogue')) {
                            nursingStore.updateWho(message.data);
                        }
                        break;
                    case 'nursing/vacutainer_tubes':
                        if ((window.location.pathname.indexOf('nursing/common') !== -1 && nursingStore.commonOpenTab === 'investigation') || (window.location.pathname.indexOf('nursing') !== -1 && nursingStore.openTab === 'investigation')) {
                            nursingStore.updateVacutainerTubes(message.data);
                        }
                        break;
                    case 'nursing/duration_unit':
                        if ((window.location.pathname.indexOf('nursing/common') !== -1 && nursingStore.commonOpenTab === 'investigation') || (window.location.pathname.indexOf('nursing') !== -1 && nursingStore.openTab === 'investigation')) {
                            nursingStore.updateDurationUnit(message.data);
                        }
                        break;
                    case 'nursing/urinary_color':
                        if ((window.location.pathname.indexOf('nursing/common') !== -1 && nursingStore.commonOpenTab === 'patient') || (window.location.pathname.indexOf('nursing') !== -1 && nursingStore.openTab === 'patient')) {
                            nursingStore.updateUrinaryColor(message.data);
                        }
                        break;
                    case 'nursing/pupil_reaction':
                        if ((window.location.pathname.indexOf('nursing/common') !== -1 && nursingStore.commonOpenTab === 'patient') || (window.location.pathname.indexOf('nursing') !== -1 && nursingStore.openTab === 'patient')) {
                            nursingStore.updatePupilReaction(message.data);
                        }
                        break;
                    case 'nursing/skin_turgor':
                        if ((window.location.pathname.indexOf('nursing/common') !== -1 && nursingStore.commonOpenTab === 'patient') || (window.location.pathname.indexOf('nursing') !== -1 && nursingStore.openTab === 'patient')) {
                            nursingStore.updateSkinTurgor(message.data);
                        }
                        break;
                    case 'nursing/speech':
                        if ((window.location.pathname.indexOf('nursing/common') !== -1 && nursingStore.commonOpenTab === 'patient') || (window.location.pathname.indexOf('nursing') !== -1 && nursingStore.openTab === 'patient')) {
                            nursingStore.updateSpeech(message.data);
                        }
                        break;
                    case 'nursing/specialStates':
                        if ((window.location.pathname.indexOf('nursing/common') !== -1 && nursingStore.commonOpenTab === 'patient') || (window.location.pathname.indexOf('nursing') !== -1 && nursingStore.openTab === 'patient')) {
                            nursingStore.updateStates(message.data);
                        }
                        break;
                    case 'nursing/patient':
                        if (window.location.pathname.indexOf('nursing') !== -1 && nursingStore.openTab === 'patient') {
                            nursingStore.updatePatientState(message.data);
                        }
                        break;
                    case 'nursing/dialogue':
                        if ((window.location.pathname.indexOf('nursing') !== -1 && nursingStore.openTab === 'dialogue') || (window.location.pathname.indexOf('nursing/common') !== -1 && nursingStore.commonOpenTab === 'dialogue')) {
                            nursingStore.updateNursingDialogue(message.data);
                        }
                        break;
                    case 'nursing/message':
                        if (window.location.pathname === '/nursing' && nursingStore.openTab === 'messages' && nursingStore.selectedCase?._id === message.data.caseId) {
                            nursingStore.updateMessage(message.data);
                        }
                        break;
                    case 'nursing/investigation':
                        if (window.location.pathname.indexOf('nursing') !== -1 && nursingStore.openTab === 'investigation') {
                            nursingStore.updateNursingInvestigation(message.data);
                        }
                        break;
                    case 'nursing/drugRange':
                        if (window.location.pathname.indexOf('nursing') !== -1 && nursingStore.openTab === 'drug') {
                            nursingStore.updateNursingDrugRange(message.data);
                        }
                        break;
                    case 'nursing/ventilation':
                        if (window.location.pathname.indexOf('nursing') !== -1 && nursingStore.openTab === 'ventilation') {
                            nursingStore.updateNursingVentilation(message.data);
                        }
                        break;
                    case 'nursing/drugType':
                        if ((window.location.pathname === '/nursing' && nursingStore.openTab === 'drug') || (window.location.pathname === '/nursing/common' && nursingStore.commonOpenTab === 'drug')) {
                            nursingStore.updateNursingTypeDrug(message.data);
                        }
                        break;
                    case 'nursing/data':
                        if (window.location.pathname === '/nursing' && nursingStore.selectedCase?._id === message.data.caseId) {
                            switch (message.data.tab) {
                                case "dialogue":
                                    if (nursingStore.openTab === 'dialogue') {
                                        getNursingDialogue(nursingStore.selectedCase._id).then(data => {
                                            nursingStore.setDialogue(data?.data ?? []);
                                            nursingStore.setDialogueErrors(data?.errors);
                                        })
                                    }
                                    break;
                                case "main":
                                    if (nursingStore.openTab === 'main') {
                                        getCaseData(nursingStore.selectedCase._id).then(data => {
                                            if (data) {
                                                nursingStore.setCaseData(data);
                                            }
                                        })
                                    }
                                    break;
                                case "patient":
                                    if (nursingStore.openTab === 'patient') {
                                        getPatientState(nursingStore.selectedCase._id).then(data => {
                                            if (data) {
                                                nursingStore.setPatientState(data);
                                            }
                                        })
                                    }
                                    break;
                                case "investigation":
                                    if (nursingStore.openTab === 'investigation') {
                                        getInvestigation(nursingStore.selectedCase._id).then(data => {
                                            nursingStore.setInvestigation(data?.data ?? []);
                                            nursingStore.setInvestigationErrors(data?.errors);
                                        })
                                    }
                                    break;
                                case "messages":
                                    if (nursingStore.openTab === 'messages') {
                                        getNursingMessages(nursingStore.selectedCase._id).then(data => {
                                            if (data) {
                                                nursingStore.setMessages(data);
                                            }
                                        })
                                    }
                                    break;
                                case "ventilation":
                                    if (nursingStore.openTab === 'ventilation') {
                                        getVentilation(nursingStore.selectedCase._id).then(data => {
                                            nursingStore.setVentilation(data?.data ?? []);
                                            nursingStore.setVentilationErrors(data?.errors);
                                        })
                                    }
                                    break;
                                case "drug":
                                case "medication":
                                    if (nursingStore.openTab === 'drug') {
                                        Promise.all([
                                            getDrugRange(nursingStore.selectedCase._id),
                                            getNursingMedication(nursingStore.selectedCase._id),
                                        ])
                                            .then(([drugs, medications]) => {
                                                if (!drugs || !medications) return;

                                                nursingStore.setDrug(drugs?.data ?? []);
                                                nursingStore.setRangeDrugsErrors(drugs?.errors);

                                                nursingStore.setMedication(medications);
                                            })
                                    } else if (nursingStore.openTab === 'medication') {
                                        getNursingMedication(nursingStore.selectedCase._id).then(data => {
                                            if (data) {
                                                nursingStore.setMedication(data);
                                            }
                                        })
                                    }
                                    break;
                            }
                        }
                        break;
                }
                if (core_store.loading) core_store.setLoading(false);
            } else if (message && message.type && message.type === 'check' && message.check) {
                switch (message.check) {
                    case 'ambulant/data':
                        switch (message.data.type) {
                            case 'passport':
                                if (message.data.caseId && ambulant.selectedSubMenuId === message.data.caseId && ambulant.openTabId === CASE_TABS.PASSPORT) {
                                    getCaseById(ambulant.selectedSubMenuId).then(({data}) => {
                                        if (data) ambulant.setPassport(data);
                                        if (core_store.loading) core_store.setLoading(false);
                                    });
                                }
                                break;
                            case 'dialogue':
                                if (message.data.caseId && ambulant.selectedSubMenuId === message.data.caseId && ambulant.openTabId === CASE_TABS.DIALOGUE) {
                                    getDialogue(ambulant.selectedSubMenuId).then(({data}) => {
                                        if (data) ambulant.setDialogue(data);
                                        if (core_store.loading) core_store.setLoading(false);
                                    });
                                }
                                break;
                            case 'examination':
                                if (message.data.caseId && ambulant.selectedSubMenuId === message.data.caseId && ambulant.openTabId === CASE_TABS.EXAMINATION) {
                                    getExamination(ambulant.selectedSubMenuId).then(({data}) => {
                                        if (data) ambulant.setExamination(data);
                                        if (core_store.loading) core_store.setLoading(false);
                                    });
                                }
                                break;
                            case 'review':
                                if (message.data.caseId && ambulant.selectedSubMenuId === message.data.caseId && ambulant.openTabId === CASE_TABS.INSPECTION) {
                                    switch (message.data.tabName) {
                                        case 'percussion':
                                            getPercussion(ambulant.selectedSubMenuId).then(({data}) => {
                                                if (data) ambulant.setPercussion(data);
                                                if (core_store.loading) core_store.setLoading(false);
                                            });
                                            break;
                                        case 'auscultation':
                                            getAuscultation(ambulant.selectedSubMenuId).then(({data}) => {
                                                if (data) ambulant.setAuscultation(data);
                                                if (core_store.loading) core_store.setLoading(false);
                                            });
                                            break;
                                        case 'palpation':
                                            getPalpation(ambulant.selectedSubMenuId).then(({data}) => {
                                                if (data) ambulant.setPalpation(data);
                                                if (core_store.loading) core_store.setLoading(false);
                                            });
                                            break;
                                        case 'visual':
                                            getVisual(ambulant.selectedSubMenuId).then(({data}) => {
                                                if (data) ambulant.setVisual(data);
                                                if (core_store.loading) core_store.setLoading(false);
                                            });
                                            break;
                                        case 'instruments':
                                            getInstruments(ambulant.selectedSubMenuId).then(({data}) => {
                                                if (data) ambulant.setInstruments(data);
                                                if (core_store.loading) core_store.setLoading(false);
                                            });
                                            break;
                                        default:
                                            break;
                                    }
                                }
                                break;
                            case 'diagnosis':
                                if (message.data.caseId && ambulant.selectedSubMenuId === message.data.caseId && ambulant.openTabId === CASE_TABS.DIAGNOSIS) {
                                    getDiagnosis(ambulant.selectedSubMenuId).then(({data}) => {
                                        if (data) ambulant.setDiagnosis(data);
                                        if (core_store.loading) core_store.setLoading(false);
                                    });
                                }
                                break;
                            case 'treatment':
                                if (message.data.caseId && ambulant.selectedSubMenuId === message.data.caseId && ambulant.openTabId === CASE_TABS.TREATMENT) {
                                    getTreatment(ambulant.selectedSubMenuId).then(({data}) => {
                                        if (data) ambulant.setTreatment(data);
                                        if (core_store.loading) core_store.setLoading(false);
                                    });
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'diagnosis':
                        if (ambulant.selectedSubMenuId) {
                            getDiagnosis(ambulant.selectedSubMenuId).then(r => {
                                if (r && r.data) {
                                    ambulant.setDiagnosis(r.data);
                                }
                            }).catch(e => console.log(e));
                        }
                        if (core_store.loading) core_store.setLoading(false);
                        break;
                }
            } else if (message && message.type && message.type === 'close') {
                switch (message.update) {
                    case 'loading':
                        if (core_store.loading) core_store.setLoading(false);
                        break;
                }
            } else if (message?.type === 'info' && message?.data) {
                user.setWS(message.data);
                updateWSID(message.data);
            }
        };

        socket.current.onerror = function (error: any) {
            console.log(error.message);
        };
    }

    useEffect(() => {
        connect();

        window.onunload = function () {
            socket.current.close(1000);
        }

        window.onbeforeunload = function () {
            socket.current.close(1000);
        }

        return () => {
            if (socket.current) socket.current.close(1000);
        }
        // eslint-disable-next-line
    }, []);
}

export default useSocket;